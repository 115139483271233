import React, { useCallback, useEffect, useState } from "react";
import "./Dashboard.css";
import {
  ArrowDownSquare,
  ArrowLeftSquare,
  ArrowRightSquare,
  BoxSeam,
  Camera,
  Clipboard,
  Person,
  Power,
  Shop,
  Upload,
} from "react-bootstrap-icons";
import axios from "axios";

const Dashboard = () => {
  const [activePage, setActivePage] = React.useState(0);
  const [openedOrder, setOpenedOrder] = React.useState(null);
  const [openedCustomer, setOpenedCustomer] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [articles, setArticles] = React.useState([]);
  const [magasins, setMagasins] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [commandes, setCommandes] = React.useState([]);
  const [createAccountAlert, setCreateAccountAlert] = React.useState({
    status: false,
    shop_id: 0,
    content: "",
  });
  const [categories, setCategories] = React.useState([
    {
      id: 1,
      name: "Sushis",
      japanese: "寿司",
      background: "sushis.png",
      sub_category: [
        {
          id: 1,
          name: "Maki",
          description:
            "Les makis sont des rouleaux de riz vinaigré entourés d'une feuille d'algue nori et garnis de poisson cru, de légumes ou d'œufs de poisson.",
          japanese: "巻き",
        },
        {
          id: 2,
          name: "Nigiiri",
          description:
            "Les nigiris sont des boulettes de riz vinaigré surmontées de poisson cru ou de fruits de mer.",
          japanese: "握り",
        },
        {
          id: 3,
          name: "California",
          description:
            "Les california sont des makis inversés, c'est-à-dire que le riz est à l'extérieur et l'algue à l'intérieur.",
          japanese: "カリフォルニア",
        },
        {
          id: 4,
          name: "Sashimi",
          description:
            "Les sashimis sont des tranches de poisson cru servies seules.",
          japanese: "刺身",
        },
        {
          id: 5,
          name: "Spring Roll",
          description:
            "Les spring rolls sont des rouleaux de printemps à base de riz, de poisson cru et de légumes.",
          japanese: "春巻き",
        },
        {
          id: 6,
          name: "Temaki",
          description:
            "Les temakis sont des cônes de riz vinaigré et d'algue nori garnis de poisson cru et de légumes.",
          japanese: "手巻き",
        },
        {
          id: 7,
          name: "Dragon Rolls et Spéciaux",
          description:
            "Les sushis spéciaux sont des créations originales de notre chef.",
          japanese: "スペシャル",
        },
        {
          id: 8,
          name: "Chiraishi",
          description:
            "Les chiraishis sont des bols de riz vinaigré garnis de poisson cru et de légumes.",
          japanese: "ちらし",
        },
      ],
    },
    {
      id: 2,
      name: "Soupe et Ramens",
      japanese: "ラーメン",
      background: "ramens.png",
      sub_category: [
        {
          id: 1,
          name: "Ramen",
          description:
            "Les ramens sont des nouilles chinoises servies dans un bouillon à base de viande ou de poisson.",
          japanese: "ラーメン",
        },
        {
          id: 2,
          name: "Soupe",
          description:
            "Les soupes sont des bouillons de viande ou de poisson servis avec des légumes et des nouilles.",
          japanese: "スープ",
        },
      ],
    },
    {
      id: 3,
      name: "Côté Chaud",
      japanese: "熱い",
      background: "hot.png",
      sub_category: [
        {
          id: 1,
          name: "Tonkatsu",
          description:
            "Les tonkatsus sont des escalopes de porc / poulet panées accompagnées de riz.",
          japanese: "とんかつ",
        },
        {
          id: 2,
          name: "Woks",
          description:
            "Nos woks sont tous à base de courgettes, carottes, oignons, gingembre frais, champignons noirs et chou chinois.",
          japanese: "炒め物",
        },
        {
          id: 3,
          name: "Crispy et Beignets",
          description:
            "Les Cripsy et Beignets sont des plats frits à base de poulet, crevettes ou légumes.",
          japanese: "クリスピー",
        },
        {
          id: 4,
          name: "Gyozas",
          description: "Les gyozas sont des raviolis japonais.",
          japanese: "餃子",
        },
        {
          id: 5,
          name: "Nems",
          description: "Les nems sont des rouleaux de printemps frits.",
          japanese: "春巻き",
        },
        {
          id: 6,
          name: "Yakitoris",
          description:
            "Les yakitoris sont des brochettes de poulet, de bœuf ou de poisson.",
          japanese: "焼き鳥",
        },
      ],
    },
    {
      id: 4,
      name: "Accompagnements",
      japanese: "おかず",
      background: "accompagnements.png",
      sub_category: [
        {
          id: 1,
          name: "Edamame",
          description:
            "Les edamames sont des fèves de soja salées à déguster à l'apéritif.",
          japanese: "枝豆",
        },
        {
          id: 2,
          name: "Salades",
          description: "Nos salades sont toutes à base de chou chinois.",
          japanese: "サラダ",
        },
        {
          id: 3,
          name: "Riz",
          description: "Nos riz sont tous à base de riz japonais.",
          japanese: "ご飯",
        },
        {
          id: 4,
          name: "Sauces",
          description: "Nos sauces sont toutes faites maison.",
          japanese: "ソース",
        },
      ],
    },
    {
      id: 5,
      name: "Menu Enfant",
      japanese: "子供",
      background: "kids.png",
      sub_category: [
        {
          id: 1,
          name: "Menu Enfant",
          description: "Nos menus enfants sont servis avec un dessert.",
          japanese: "子供",
        },
      ],
    },
    {
      id: 6,
      name: "Desserts",
      japanese: "デザート",
      background: "mochi.png",
      sub_category: [
        {
          id: 1,
          name: "Mochis",
          description:
            "Les mochis sont des gâteaux de riz gluant fourrés à déguster glacés.",
          japanese: "餅",
        },
        {
          id: 2,
          name: "Autres",
          description: "Les autres desserts.",
          japanese: "その他",
        },
      ],
    },
    {
      id: 7,
      name: "Boissons",
      japanese: "飲み物",
      background: "drinks.png",
      sub_category: [
        {
          id: 1,
          name: "Softs",
          description: "Tous nos softs.",
          japanese: "ソーダ",
        },
        {
          id: 2,
          name: "Bières",
          description: "Toutes nos bières. D'ici et d'asie",
          japanese: "ビール",
        },
        {
          id: 3,
          name: "Vins",
          description: "Tous nos vins.",
          japanese: "ワイン",
        },
        {
          id: 6,
          name: "Cocktails",
          description: "Nos cocktails tout prêt.",
          japanese: "カクテル",
        },
      ],
    },
    {
      id: 8,
      name: "Box",
      japanese: "ボックス",
      background: "box.png",
      sub_category: [{ id: 1, name: "Big" }],
    },
  ]);
  const [time_slots, setTimeSlots] = React.useState({
    days: [
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
      "Dimanche",
    ],
    hours: [
      {
        id: 1,
        start: "9:00",
        end: "10:00",
      },
      {
        id: 2,
        start: "10:00",
        end: "11:00",
      },
      {
        id: 3,
        start: "11:00",
        end: "12:00",
      },
      {
        id: 4,
        start: "12:00",
        end: "13:00",
      },
      {
        id: 5,
        start: "13:00",
        end: "14:00",
      },
      {
        id: 6,
        start: "14:00",
        end: "15:00",
      },
      {
        id: 7,
        start: "15:00",
        end: "16:00",
      },
      {
        id: 8,
        start: "16:00",
        end: "17:00",
      },
      {
        id: 9,
        start: "17:00",
        end: "18:00",
      },
      {
        id: 10,
        start: "18:00",
        end: "19:00",
      },
      {
        id: 11,
        start: "19:00",
        end: "20:00",
      },
      {
        id: 12,
        start: "20:00",
        end: "21:00",
      },
      {
        id: 13,
        start: "21:00",
        end: "22:00",
      },
      {
        id: 14,
        start: "22:00",
        end: "23:00",
      },
    ],
  });
  const [need_save, setNeedSave] = React.useState(false);
  const [magNeedSave, setMagNeedSave] = React.useState(false);
  const [cliNeedSave, setCliNeedSave] = React.useState(false);
  const [comNeedSave, setComNeedSave] = React.useState(false);
  const [contentLoadStep, setContentLoadStep] = React.useState(0);
  const [newCategory, setNewCategory] = React.useState({
    status: false,
    name: "",
    japanese: "",
    background: "",
  });
  const [newSubCategory, setNewSubCategory] = React.useState({
    status: false,
    name: "",
    description: "",
    japanese: "",
  });
  const [search, setSearch] = React.useState({
    id: null,
    value: "",
    results: [],
  });
  const [searchClient, setSearchClient] = React.useState({
    id: null,
    value: "",
    results: [],
  });
  const [user, setUser] = React.useState({
    id: null,
    type: null,
    name: null,
    surname: null,
    mail: null,
  });
  const [myMagasin, setMyMagasin] = React.useState({
    id: null,
    name: null,
    address: null,
    postal_code: null,
    phone: null,
    email: null,
    city: null,
    time_slots: [],
    need_save: false,
  });

  useState(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.location = "/login";
    } else {
      setToken(token);
    }
  });
  const openArticle = (id) => {
    setArticles(
      articles.map((article) => {
        if (article.id === id && article.opened !== 1) {
          article.opened = 1;
        } else if (article.id === id && article.opened === 1) {
          article.opened = 0;
        } else {
          article.opened = 0;
        }
        return article;
      })
    );
  };
  const load_articles = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const response = await axios.post("https://sushinari.fr/api/articles", {
      type: 0,
      token: token,
    });

    if (response.data.success && response.data.articles) {
      setArticles(
        response.data.articles
          .map((article) => {
            // on vérifie si l'article est aux normes
            if (article.id) {
              return {
                id: article.id,
                name: article.name,
                ref: article.ref,
                category: article.category,
                sub_category: article.sub_category,
                image: article.picture,
                description: article.description,
                price: article.price,
                status: article.status,
                menu_midi: article.menu_midi,
                menu_soir: article.menu_soir,
                dispo_magasins: article.dispo_magasins,
                cc_menu: article.cc_menu,
                opened: 0,
              };
            } else {
              return null;
            }
          })
          .filter((article) => article !== null)
      );
      setContentLoadStep(1);
    } else {
      console.log("Erreur lors du chargement des articles");
    }
  }, []);
  const load_commandes = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const response = await axios.post("https://sushinari.fr/api/commandes", {
      type: 0,
      token: token,
    });

    if (response.data.success && response.data.commandes) {
      setCommandes(
        response.data.commandes.map((commande) => {
          if (commande.id) {
            const client_id = commande.client ? parseInt(commande.client) : 0;
            const client = clients.find((client) => client.id === client_id);

            return {
              id: commande.id ? commande.id : null,
              client: {
                id: client_id,
                name: client ? client.name : "",
                surname: client ? client.surname : "",
                address: client ? client.address : "",
                postal_code: client ? client.postal_code : "",
                city: client ? client.city : "",
              },
              date: commande.date ? commande.date : new Date(),
              price: commande.price ? commande.price : 0,
              status: commande.status ? commande.status : 0,
              articles: commande.articles
                ? typeof commande.articles === "string"
                  ? JSON.parse(commande.articles)
                  : commande.articles
                : [],
              opened: 0,
            };
          } else {
            return null;
          }
        })
      );
      setContentLoadStep(4);
    } else {
      console.log("Erreur lors du chargement des commandes");
    }
  }, []);
  const load_clients = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    } else if (user.type === 1) {
      return;
    }

    const response = await axios.post("https://sushinari.fr/api/clients", {
      type: 0,
      token: token,
    });

    if (response.data.success && response.data.clients) {
      setClients(
        response.data.clients.map((client) => {
          if (client.id) {
            return {
              id: client.id,
              name: client.name,
              surname: client.surname,
              email: client.email,
              phone: client.phone,
              address: client.address,
              postal_code: client.postal_code,
              city: client.city,
              favorite_shop: client.favorite_shop,
              opened: 0,
            };
          } else {
            return null;
          }
        })
      );
      setContentLoadStep(2);
    } else {
      console.log("Erreur lors du chargement des clients");
      console.log(response.data);
      if (response.data.error === "token") {
        window.location = "/login";
      }
    }
  }, []);

  const save_articles = useCallback(async () => {
    if (need_save) {
      const token = localStorage.getItem("token");

      if (!token) {
        return;
      }

      setNeedSave(false);

      const response = await axios.post(
        "https://sushinari.fr/api/articles_change",
        {
          articles: articles,
          token: token,
        }
      );

      if (response.data.success) {
        if (response.data.newArticles !== 0) {
          setArticles(
            articles.map((article) => {
              if (article.id === 0 || article.id === null) {
                article.id = response.data.newArticles;
                console.log("ID AJOUTÉ : ", article.id);
              }
              return article;
            })
          );
        }
      } else {
        console.log("Erreur lors de l'enregistrement des articles");
      }
    }
  }, [articles, need_save]);
  const save_magasins = useCallback(async () => {
    if (magNeedSave) {
      const token = localStorage.getItem("token");

      if (!token) {
        return;
      }

      setMagNeedSave(false);
      const response = await axios.post(
        "https://sushinari.fr/api/magasins_change",
        {
          magasins: magasins,
          token: token,
        }
      );
      if (response.data.success) {
        if (response.data.newMagasins !== 0) {
          setMagasins(
            magasins.map((magasin) => {
              if (magasin.id === 0) {
                magasin.id = response.data.newMagasins;
              }
              return magasin;
            })
          );
        }
      } else {
        console.log("Erreur lors de l'enregistrement des magasins");
      }
    }
  }, [magasins, magNeedSave]);
  const save_clients = useCallback(async () => {
    if (cliNeedSave) {
      const token = localStorage.getItem("token");

      if (!token) {
        return;
      }

      const response = await axios.post(
        "https://sushinari.fr/api/clients_change",
        {
          clients: clients,
          token: token,
        }
      );
      if (response.data.success) {
        if (response.data.newClients !== 0) {
          setClients(
            clients.map((client) => {
              if (client.id === 0) {
                client.id = response.data.newClients;
              }
              return client;
            })
          );
        }
        setCliNeedSave(false);
      } else {
        console.log("Erreur lors de l'enregistrement des clients");
        setCliNeedSave(false);
      }
    }
  }, [clients, cliNeedSave]);
  const save_commandes = useCallback(async () => {
    if (comNeedSave) {
      const token = localStorage.getItem("token");

      if (!token) {
        return;
      }

      const response = await axios.post(
        "https://sushinari.fr/api/commandes_change",
        {
          commandes: commandes,
          token: token,
        }
      );
      if (response.data.success) {
        if (response.data.newCommandes !== 0) {
          setCommandes(
            commandes.map((commande) => {
              if (commande.id === 0) {
                commande.id = response.data.newCommandes;
              }
              return commande;
            })
          );
        }
        setComNeedSave(false);
      } else {
        console.log("Erreur lors de l'enregistrement des commandes");
        setComNeedSave(false);
      }
    }
  }, [commandes, comNeedSave]);

  // Fonction pour créer un article
  const create_article = () => {
    setArticles([
      ...articles,
      {
        id: 0,
        name: "Nouvel article",
        category: 1,
        sub_category: 0,
        price: 0,
        status: 0,
        menu_midi: 0,
        menu_soir: 0,
        dispo_magasins: 0,
        cc_menu: 0,
        opened: 1,
      },
    ]);
  };
  // Fonction pour supprimer un article
  const erase_article = async (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    // On demande confirmation
    if (!window.confirm("Voulez-vous vraiment supprimer cet article ?")) {
      return;
    }
    setArticles(articles.filter((article) => article.id !== id));
    const response = await axios.post(
      "https://sushinari.fr/api/article_delete",
      {
        id: id,
        token: token,
      }
    );
    if (response.data.success) {
      console.log("Article supprimé");
    } else {
      console.log("Erreur lors de la suppression de l'article");
    }
  };
  // Fonction pour mettre à jour un article
  const update_article = (e, id, field) => {
    // TODO : Problème de répétition des articles
    setArticles(
      articles.map((article) => {
        if (article.id === id) {
          article[field] = e.target.value;
        }
        return article;
      })
    );
  };
  // Fonction pour activer/désactiver un bouton
  const toggle_button_article = (e, id, field) => {
    setArticles(
      articles.map((article) => {
        if (article.id === id) {
          article[field] = !article[field];
        }
        return article;
      })
    );
    setNeedSave(true);
  };
  // Charger l'ensemble des magasins
  const load_magasins = React.useCallback(async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const response = await axios.post("https://sushinari.fr/api/magasins", {
      type: 0,
      token: token,
    });

    if (response.data.success && response.data.magasins) {
      setMagasins(
        response.data.magasins.map((magasin) => {
          if (magasin.id) {
            return {
              id: magasin.id,
              status: magasin.status,
              name: magasin.name,
              address: magasin.address,
              postal_code: magasin.postal_code,
              phone: magasin.phone,
              email: magasin.email,
              city: magasin.city,
              time_slots: magasin.time_slots
                ? JSON.parse(magasin.time_slots)
                : [],
              opened: 0,
              user_id: magasin.user_id,
            };
          } else {
            return null;
          }
        })
      );
      setContentLoadStep(3);
    } else {
      console.log("Erreur lors du chargement des magasins");
    }
  }, []);
  // Création d'un magasin
  const create_magasin = () => {
    console.log("Création d'un magasin");
    setMagasins([
      ...magasins,
      {
        id: 0,
        name: "Nouveau magasin",
        address: "",
        postal_code: "",
        city: "",
        last_delivery: "",
        alert_number: 0,
        opened: 1,
      },
    ]);
  };
  // Développer la section d'un magasin
  const open_magasin = (id) => {
    console.log("OPEN MAG ID : ", id);
    setMagasins(
      magasins.map((magasin) => {
        if (magasin.id === id && magasin.opened !== 1) {
          magasin.opened = 1;
        } else if (magasin.id === id && magasin.opened === 1) {
          magasin.opened = 0;
        } else {
          magasin.opened = 0;
        }
        return magasin;
      })
    );
  };
  // Supprimer un magasin
  const erase_magasin = async (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    setMagasins(magasins.filter((magasin) => magasin.id !== id));
    const response = await axios.post(
      "https://sushinari.fr/api/magasin_delete",
      {
        id: id,
        token: token,
      }
    );
  };
  // Création d'un client
  const create_client = () => {
    setClients([
      ...clients,
      {
        id: 0,
        name: "Nouveau client",
        surname: "",
        email: "",
        phone: "",
        address: "",
        postal_code: "",
        city: "",
        favorite_shop: 0,
        opened: 1,
      },
    ]);
    setCliNeedSave(true);
  };
  // Supprimer un client
  const erase_client = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    setClients(clients.filter((client) => client.id !== id));
    const response = await axios.post(
      "https://sushinari.fr/api/client_delete",
      {
        id: id,
        token: token,
      }
    );
  };
  // Mettre à jour un client
  const updateClient = (e, id, field) => {
    setClients(
      clients.map((client) => {
        if (client.id === id) {
          client[field] = e.target.value;
        }
        return client;
      })
    );
  };
  // Création d'une commande
  const create_commande = () => {
    setCommandes([
      ...commandes,
      {
        id: 0,
        client: 0,
        date: "",
        price: 0,
        status: 0,
        opened: 1,
        articles: [],
      },
    ]);
    setComNeedSave(true);
  };
  // Supprimer une commande
  const erase_commande = async (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    setCommandes(commandes.filter((commande) => commande.id !== id));
    const response = await axios.post(
      "https://sushinari.fr/api/commande_delete",
      {
        id: id,
        token: token,
      }
    );
  };
  // Ajouter un article à une commande
  const addArticleToOrder = () => {
    setCommandes(
      commandes.map((commande) => {
        if (commande.id === openedOrder) {
          if (!commande.articles) {
            commande.articles = [];
          }
          commande.articles.push({
            id: commande.articles.length + 1,
            name: "Nouvel article",
            quantity: 1,
            check: 0,
          });
        }
        return commande;
      })
    );
    setComNeedSave(true);
  };
  // Modifier un article d'une commande
  const updateArticleInOrder = (e, id, field) => {
    setCommandes(
      commandes.map((commande) => {
        if (commande.id === openedOrder) {
          commande.articles.map((article) => {
            if (article.id === id) {
              if (field === "check") {
                article[field] = !article[field];
              } else {
                article[field] = e.target.value;
              }
            }
          });
        }
        return commande;
      })
    );
    setComNeedSave(true);
  };
  // Supprimer un article d'une commande
  const eraseArticleInOrder = (id) => {
    setCommandes(
      commandes.map((commande) => {
        if (commande.id === openedOrder) {
          commande.articles = commande.articles.filter(
            (article) => article.id !== id
          );
        }
        return commande;
      })
    );
  };
  // Rechercher un article à ajouter à une commande
  const searchArticleInOrder = async (e, id, field) => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    setCommandes(
      commandes.map((commande) => {
        if (commande.id === openedOrder) {
          commande.articles.map((article) => {
            if (article.id === id) {
              article[field] = e.target.value;
            }
          });
        }
        return commande;
      })
    );
    const response = await axios.post("https://sushinari.fr/api/articles", {
      type: 1,
      search: e.target.value,
      token: token,
    });

    if (response.data.success && response.data.articles) {
      setSearch({
        id: id,
        value: e.target.value,
        results: response.data.articles,
      });
    } else {
      console.log("Erreur lors de la recherche d'articles");
    }
  };
  // Choisir un article à ajouter à une commande
  const chooseArticleInOrder = (article, id) => {
    setCommandes(
      commandes.map((commande) => {
        if (commande.id === openedOrder) {
          commande.articles.map((item) => {
            if (item.id === id) {
              item.name = article.name;
            }
          });
        }
        return commande;
      })
    );
    setSearch({
      id: null,
      value: "",
      results: [],
    });
    setComNeedSave(true);
  };
  const dissociateClientFromOrder = () => {
    setCommandes(
      commandes.map((commande) => {
        if (commande.id === openedOrder) {
          commande.client = {
            id: null,
            name: "",
            surname: "",
            email: "",
            phone: "",
            address: "",
            postal_code: "",
            city: "",
          };
        }
        return commande;
      })
    );
    setComNeedSave(true);
  };
  const upload_image = async (e, id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("id", id);
    const response = await axios.post(
      "https://sushinari.fr/api/upload_image",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);
    if (response.status === 200) {
      if (response.data.success) {
        setArticles(
          articles.map((article) => {
            if (article.id === id) {
              article.image = response.data.image;
            }
            return article;
          })
        );
      } else {
        console.log("Erreur lors de l'upload de l'image");
      }
    } else {
      if (response.status === 413) {
        alert("Il est plus lourd que toi le fichier !");
      }
    }
  };
  const openCategory = (id) => {
    setCategories(
      categories.map((category) =>
        category.id === id
          ? {
              ...category,
              opened: category.opened === 1 ? 0 : 1,
            }
          : category
      )
    );
  };
  const update_magasin = (e, id, field) => {
    console.log("UPDATE MAGASIN : ", id, field, e.target.value);
    setMagasins(
      magasins.map((magasin) => {
        if (magasin.id === id) {
          magasin[field] = e.target.value;
        }
        return magasin;
      })
    );
  };
  const update_magasin_time_slot = (e, id, time_spot, field) => {
    if (field === "type") {
      setMagasins(
        magasins.map((magasin) => {
          if (magasin.id === id) {
            // On a trouvé le magasin
            if (e.target.value === "1") {
              // Journée entière on efface les time_slots.hours du jour sélectionné
              // On vérifie si le jour est déjà disponible dans les time_slots sinon on le crée avec day =time_spot et hours=""
              if (
                magasin.time_slots &&
                magasin.time_slots.find((slot) => slot.day === time_spot)
              ) {
                magasin.time_slots.find(
                  (slot) => slot.day === time_spot
                ).hours = "";
              } else if (magasin.time_slots) {
                magasin.time_slots.push({
                  day: time_spot,
                  hours: "",
                });
              }
            } else if (e.target.value === "3") {
              // Le jour est indisponible on le supprime
              if (
                magasin.time_slots &&
                magasin.time_slots.find((slot) => slot.day === time_spot)
              )
                magasin.time_slots = magasin.time_slots.filter(
                  (slot) => slot.day !== time_spot
                );
            }
          }
          return magasin;
        })
      );
    }
  };
  const create_new_category = async () => {
    const token = localStorage.getItem("token");

    // On vérifie que le nome de la catégorie soit valide (comprend lettres, chiffres, accent, espace, tiret)
    if (
      newCategory.name === "" ||
      !newCategory.name.match(/^[a-zA-Z0-9À-ÿ\s\-]+$/)
    ) {
      return;
    }

    const response = await axios.post(
      "https://sushinari.fr/api/category_create",
      {
        name: newCategory.name,
        japanese: newCategory.japanese,
        background: newCategory.background,
        token: token,
      }
    );
    if (response.data.success) {
      setCategories([
        ...categories,
        {
          id: response.data.id,
          name: newCategory.name,
          japanese: newCategory.japanese,
          background: newCategory.background,
          opened: 0,
        },
      ]);
      setNewCategory({
        status: false,
        name: "",
        japanese: "",
        background: "",
      });
    } else {
      console.log("Erreur lors de la création de la catégorie");
    }
  };
  const create_new_sub_category = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    // On vérifie que le nome de la catégorie soit valide (comprend lettres, chiffres, accent, espace, tiret)
    if (
      newSubCategory.name === "" ||
      !newSubCategory.name.match(/^[a-zA-Z0-9À-ÿ\s\-]+$/)
    ) {
      console.log("Nom de sous-catégorie invalide");
      return;
    }

    const response = await axios.post(
      "https://sushinari.fr/api/sub_category_create",
      {
        name: newSubCategory.name,
        description: newSubCategory.description,
        japanese: newSubCategory.japanese,
        cat_id: newSubCategory.category,
        sub_cat_id:
          categories.find((category) => category.id === newSubCategory.category)
            .sub_category.length + 1,
        token: token,
      }
    );
    if (response.data.success) {
      setCategories(
        categories.map((category) => {
          if (category.id === newSubCategory.category) {
            category.sub_category.push({
              id: response.data.id,
              name: newSubCategory.name,
              description: newSubCategory.description,
              japanese: newSubCategory.japanese,
            });
          }
          return category;
        })
      );
      setNewSubCategory({
        status: false,
        name: "",
        description: "",
        japanese: "",
      });
    } else {
      console.log("Erreur lors de la création de la sous-catégorie");
    }
  };
  const load_categories = React.useCallback(async () => {
    const response = await axios.post(
      "https://sushinari.fr/api/categories",
      {}
    );
    if (response.data.success && response.data.categories) {
      setCategories(
        response.data.categories.map((category) => ({
          id: category.id,
          name: category.name,
          japanese: category.japanese,
          sub_category:
            category.sub_categories.length > 0
              ? category.sub_categories.map((subcategory) => ({
                  id: subcategory.id,
                  name: subcategory.name,
                  description: subcategory.description,
                  japanese: subcategory.japanese,
                }))
              : [],
          opened: 0,
        }))
      );
    } else {
      console.log("Erreur de chargement des catégories");
    }
  }, []);
  const generateAccount = async (shop_id) => {
    const choosen_shop = magasins.find((magasin) => magasin.id === shop_id);
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    if (!choosen_shop) {
      setCreateAccountAlert({
        status: true,
        message: "Magasin non trouvé",
        shop_id: shop_id,
        token: token,
      });
      return;
    } else if (!choosen_shop.email) {
      setCreateAccountAlert({
        status: true,
        message: "Email du magasin non renseigné",
        shop_id: shop_id,
      });
      return;
    }

    const response = await axios.post(
      "https://sushinari.fr/api/account_create",
      {
        name: choosen_shop.name,
        type: 0,
        shop: shop_id,
        role: 0,
        mail: choosen_shop.email,
      }
    );
    if (response.data.success) {
      console.log("Compte généré");
    } else {
      console.log("Erreur lors de la génération du compte");
    }
  };
  const logout = () => {
    // On demande confirmation
    if (!window.confirm("Voulez-vous vraiment vous déconnecter ?")) {
      return;
    }
    localStorage.removeItem("token");
    window.location = "/login";
  };
  const update_my_magasin = (e, field) => {
    setMyMagasin({
      ...myMagasin,
      [field]: e.target.value,
      need_save: true,
    });
  };
  const modifyMagasin = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const response = await axios.post(
      "https://sushinari.fr/api/magasin_modify",
      {
        id: myMagasin.id,
        name: myMagasin.name,
        address: myMagasin.address,
        postal_code: myMagasin.postal_code,
        phone: myMagasin.phone,
        email: myMagasin.email,
        city: myMagasin.city,
        time_slots: JSON.stringify(myMagasin.time_slots),
        token: token,
      }
    );

    if (response.data.success) {
      setMyMagasin({
        ...myMagasin,
        need_save: false,
      });
    } else {
      console.log("Erreur lors de la modification du magasin");
    }
  };

  useEffect(() => {
    console.log("Token : ", token);
    // On vérifie que l'utilisateur est connecté
    if (!token) {
      window.location = "/login";
    } else {
      // On charge user
      axios
        .post("https://sushinari.fr/api/user", {
          token: token,
        })
        .then((response) => {
          if (response.data.success) {
            setUser({
              id: response.data.user.id,
              shop: response.data.user.shop,
              name: response.data.user.name,
              username: response.data.user.username,
              mail: response.data.user.mail,
              type: response.data.user.type,
              role: response.data.user.role,
            });
            console.log("Utilisateur chargé");
          } else {
            console.log("Erreur lors du chargement de l'utilisateur");
            if (response.data.error === "token") {
              window.location = "/login";
            }
          }
        });
    }
  }, [token]);
  useEffect(() => {
    contentLoadStep === 0 && load_categories();
    contentLoadStep === 0 && load_articles();
    contentLoadStep === 1 && load_clients();
    contentLoadStep === 2 && load_magasins();
    contentLoadStep === 3 && load_commandes();
  }, [
    contentLoadStep,
    load_articles,
    load_clients,
    load_magasins,
    load_commandes,
    load_categories,
  ]);
  useEffect(() => {
    save_articles();
  }, [save_articles, need_save]);
  useEffect(() => {
    save_magasins();
  }, [save_magasins]);
  useEffect(() => {
    save_clients();
  }, [clients, save_clients]);
  useEffect(() => {
    save_commandes();
    setSearch({
      id: null,
      value: "",
      results: [],
    });
  }, [commandes, save_commandes]);

  return (
    <div className="Dashboard">
      <div className="Dashboard_title">
        <div className="Dashboard_title_text">
          DASHBOARD
          <br />
          <span className="Dashboard_title_japanese">ダッシュボード</span>
        </div>
        <div className="Dashboard_title_logout" onClick={logout}>
          <Power size={20} />
        </div>
      </div>
      <div className="Dashboard_content">
        {activePage === 0 && openedOrder === null && (
          <div className="Dashboard_content_page">
            <div className="Dashboard_content_more_order_button">
              <div onClick={create_commande}>Créer une commande</div>
            </div>
            <div className="Dashboard_content_section">
              <div className="Dashboard_content_title">Dernières commandes</div>
              <div className="Dashboard_content_section_category_articles">
                {commandes.map((commande) => (
                  <div
                    className="Dashboard_content_section_order"
                    onClick={() => setOpenedOrder(commande.id)}
                  >
                    <div className="Dashboard_content_section_order_number">
                      {commande.id}
                    </div>
                    <div className="Dashboard_content_section_order_date">
                      {
                        // On affiche la date de la commande formatée jj/mm/aaaa
                        new Date(commande.date).toLocaleDateString("fr-FR")
                      }
                    </div>
                    <div className="Dashboard_content_section_order_price">
                      {commande.price} €
                    </div>
                    <div className="Dashboard_content_section_order_status">
                      {commande.status === 0 ? "En cours" : "Terminée"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {(user.type === 0 || user.type === "0") &&
          activePage === 1 &&
          openedCustomer === null && (
            <div className="Dashboard_content_page">
              <div className="Dashboard_content_more_client_button">
                <div onClick={create_client}>Créer un client</div>
              </div>
              <div className="Dashboard_content_section">
                <div className="Dashboard_content_title">Derniers clients</div>
                {clients.map((client) => (
                  <div
                    className="Dashboard_content_section_order"
                    onClick={() => setOpenedCustomer(client.id)}
                  >
                    <div className="Dashboard_content_section_order_number">
                      {client.id}
                    </div>
                    <div className="Dashboard_content_section_order_nom">
                      {client.name} {client.surname}
                    </div>
                    <div className="Dashboard_content_section_order_last_order_date">
                      01/01/2021
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        {activePage === 2 && (
          <div className="Dashboard_content_page">
            {(user.type === 0 || user.type === "0") && (
              <>
                <div className="Dashboard_content_more_magasin_button">
                  <div onClick={create_magasin}>Créer un magasin</div>
                </div>
                <div className="Dashboard_content_section">
                  <div className="Dashboard_content_title">Magasins</div>
                  {magasins.map((magasin, index) => (
                    <div className="Dashboard_content_section_order_magasin">
                      <div
                        className="Dashboard_content_section_order_title"
                        onClick={() => open_magasin(magasin.id)}
                      >
                        <div className="Dashboard_content_section_order_number">
                          {magasin.id}
                        </div>
                        <div className="Dashboard_content_section_order_nom">
                          {magasin.name}
                        </div>
                        <div className="Dashboard_content_section_order_last_order_date">
                          Livré le: {magasin.last_delivery}
                        </div>
                        <div className="Dashboard_content_section_order_status">
                          <div className="Dashboard_content_section_order_pastille">
                            {magasin.alert_number}
                          </div>
                        </div>
                      </div>
                      {magasin.opened === 1 && (
                        <div className="Dashboard_content_section_order_content">
                          <input
                            type="text"
                            className="Dashboard_content_section_order_input"
                            value={magasin.name}
                            onChange={(e) =>
                              update_magasin(e, magasin.id, "name")
                            }
                            onBlur={() => setMagNeedSave(true)}
                            placeholder="Nom"
                          />
                          <input
                            type="text"
                            className="Dashboard_content_section_order_input"
                            value={magasin.phone}
                            onChange={(e) =>
                              update_magasin(e, magasin.id, "phone")
                            }
                            onBlur={() => setMagNeedSave(true)}
                            placeholder="Téléphone"
                          />
                          <input
                            type="text"
                            className="Dashboard_content_section_order_input"
                            value={magasin.email}
                            onChange={(e) =>
                              update_magasin(e, magasin.id, "email")
                            }
                            onBlur={() => setMagNeedSave(true)}
                            placeholder="Email"
                          />
                          <input
                            type="text"
                            className="Dashboard_content_section_order_input"
                            value={magasin.address}
                            onChange={(e) =>
                              update_magasin(e, magasin.id, "address")
                            }
                            onBlur={() => setMagNeedSave(true)}
                            placeholder="Adresse"
                          />
                          <input
                            type="text"
                            className="Dashboard_content_section_order_input"
                            value={magasin.postal_code}
                            onChange={(e) =>
                              update_magasin(e, magasin.id, "postal_code")
                            }
                            onBlur={() => setMagNeedSave(true)}
                            placeholder="Code postal"
                          />
                          <input
                            type="text"
                            className="Dashboard_content_section_order_input"
                            value={magasin.city}
                            onChange={(e) =>
                              update_magasin(e, magasin.id, "city")
                            }
                            onBlur={() => setMagNeedSave(true)}
                            placeholder="Ville"
                          />

                          <div className="Dashboard_content_section_order_time_spots">
                            {time_slots.days.map((time_spot) => (
                              <div className="Dashboard_content_section_order_time_spot">
                                <div className="Dashboard_content_section_order_time_spot_title">
                                  {time_spot}
                                </div>
                                <select
                                  className="Dashboard_content_section_order_time_spot_type"
                                  onChange={(e) =>
                                    update_magasin_time_slot(
                                      e,
                                      magasin.id,
                                      time_spot,
                                      "type"
                                    )
                                  }
                                  value={
                                    magasin.time_slots &&
                                    magasin.time_slots.find(
                                      (slot) => slot.day === time_spot
                                    )
                                      ? 1
                                      : 3
                                  }
                                >
                                  <option value="1">Journée entière</option>
                                  {/* <option value="2">Sélectionné heures</option> */}
                                  <option value="3">Pas disponible</option>
                                </select>

                                {time_spot.hours && (
                                  <div className="Dashboard_content_section_order_time_spot_hours">
                                    {time_spot.hours &&
                                      time_spot.hours.map((hour) => (
                                        <div className="Dashboard_content_section_order_time_spot_hour">
                                          <div className="Dashboard_content_section_order_time_spot_hour_title">
                                            {hour.start} - {hour.end}
                                          </div>
                                          <input
                                            type="checkbox"
                                            className="Dashboard_content_section_order_time_spot_hour_checkbox"
                                            onChange={(e) =>
                                              update_magasin_time_slot(
                                                e,
                                                magasin.id,
                                                time_spot,
                                                "hours"
                                              )
                                            }
                                          />
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            ))}

                            {/* <div className="Dashboard_content_section_order_time_spot_day">
                          <div className="Dashboard_content_section_order_time_spot_day_title">
                            Lundi
                          </div>
                          <select className="Dashboard_content_section_order_time_spot_type">
                            <option value="1">Journée entière</option>
                            <option value="2">Sélectionné heures</option>
                            <option value="3">Pas disponible</option>
                          </select>
                          <div className="Dashboard_content_section_order_time_spot_hours">
                            <div className="Dashboard_content_section_order_time_spot_hour">
                              <div className="Dashboard_content_section_order_time_spot_hour_title">
                                8h - 9h
                              </div>
                              <input
                                type="checkbox"
                                className="Dashboard_content_section_order_time_spot_hour_checkbox"
                              />
                            </div>
                          </div>
                        </div> */}
                          </div>
                          <div
                            className="Dashboard_content_section_order_button"
                            onClick={() => erase_magasin(magasin.id)}
                          >
                            Supprimer
                          </div>
                          <div className="Dashboard_content_section_order_account_section">
                            <div className="Dashboard_content_section_order_account_title">
                              Compte
                            </div>
                            {magasin.user_id !== 0 ? (
                              <>
                                <div className="Dashboard_content_section_order_account_text">
                                  Veuillez générer un compte pour rendre ce
                                  magasin accessible à votre pârtenaire.
                                </div>
                                <div
                                  className="Dashboard_content_section_order_account_button"
                                  onClick={() => generateAccount(magasin.id)}
                                >
                                  Générer un compte
                                </div>

                                <div className="Dashboard_content_section_order_account_alert">
                                  {createAccountAlert.status &&
                                    createAccountAlert.shop_id === magasin.id &&
                                    createAccountAlert.message}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="Dashboard_content_section_order_account_text">
                                  Un compte est déjà associé à ce magasin.
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
            {(user.type === 1 || user.type === "1") && (
              <>
                <div className="Dashboard_content_section">
                  <div className="Dashboard_content_title">Votre Magasin</div>
                  <div className="Dashboard_content_section_form">
                    <input
                      type="text"
                      className="Dashboard_content_section_form_input"
                      placeholder="Nom"
                      value={myMagasin.name}
                      onChange={(e) => update_my_magasin(e, "name")}
                    />
                    <input
                      type="text"
                      className="Dashboard_content_section_form_input"
                      placeholder="Téléphone"
                      value={myMagasin.phone}
                      onChange={(e) => update_my_magasin(e, "phone")}
                    />
                    <input
                      type="text"
                      className="Dashboard_content_section_form_input"
                      placeholder="Email"
                      value={myMagasin.mail}
                      onChange={(e) => update_my_magasin(e, "mail")}
                    />
                    <input
                      type="text"
                      className="Dashboard_content_section_form_input"
                      placeholder="Adresse"
                      value={myMagasin.address}
                      onChange={(e) => update_my_magasin(e, "address")}
                    />
                    <input
                      type="text"
                      className="Dashboard_content_section_form_input"
                      placeholder="Code postal"
                      value={myMagasin.postal_code}
                      onChange={(e) => update_my_magasin(e, "postal_code")}
                    />
                    <input
                      type="text"
                      className="Dashboard_content_section_form_input"
                      placeholder="Ville"
                      value={myMagasin.city}
                      onChange={(e) => update_my_magasin(e, "city")}
                    />
                    <div
                      className="Dashboard_content_section_form_button"
                      onClick={modifyMagasin}
                    >
                      Modifier
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {(user.type === 0 || user.type === "0") && activePage === 3 && (
          <div className="Dashboard_content_page">
            <div
              className="Dashboard_content_more_article_button"
              onClick={create_article}
            >
              Créer un article
            </div>
            <div className="Dashboard_content_categories_edit">
              {!newCategory.status && (
                <div
                  className="Dashboard_content_categories_edit_button"
                  onClick={() => setNewCategory({ status: true })}
                >
                  Créer une catégorie
                </div>
              )}
              {newCategory.status && (
                <div className="Dashboard_content_categories_edit_content">
                  <input
                    type="text"
                    placeholder="Nom de la catégorie"
                    className="Dashboard_content_categories_edit_content_name"
                    onChange={(e) =>
                      setNewCategory({ ...newCategory, name: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    placeholder="Nom Japonnais"
                    className="Dashboard_content_categories_edit_content_name"
                    onChange={(e) =>
                      setNewCategory({
                        ...newCategory,
                        japanese: e.target.value,
                      })
                    }
                  />
                  <div
                    className="Dashboard_content_categories_edit_content_button"
                    onClick={create_new_category}
                  >
                    Créer
                  </div>
                  <div
                    className="Dashboard_content_categories_edit_content_back"
                    onClick={() => setNewCategory({ status: false })}
                  >
                    Annuler
                  </div>
                </div>
              )}
            </div>
            <div className="Dashboard_content_articles_section">
              <div className="Dashboard_content_title">Articles</div>
              <div className="Dashboard_content_section_articles">
                {categories &&
                  categories.map((category) => (
                    <div className="Dashboard_content_section_category">
                      <div
                        className="Dashboard_content_section_category_title"
                        onClick={() => openCategory(category.id)}
                      >
                        {category.name}
                        {category.opened !== 1 ? (
                          <ArrowRightSquare size={24} color="#FFF" />
                        ) : (
                          <ArrowDownSquare size={24} color="#FFF" />
                        )}
                      </div>
                      {category.opened === 1 && (
                        <div className="Dashboard_content_section_category_articles">
                          <div className="Dashboard_content_section_new_sub_cat_section">
                            {!newSubCategory.status && (
                              <div
                                className="Dashboard_content_section_new_sub_cat_button"
                                onClick={() =>
                                  setNewSubCategory({
                                    status: true,
                                    category: category.id,
                                  })
                                }
                              >
                                Créer une sous-catégorie
                              </div>
                            )}
                            {newSubCategory.status &&
                              newSubCategory.category === category.id && (
                                <div className="Dashboard_content_section_new_sub_cat_form">
                                  <input
                                    type="text"
                                    className="Dashboard_content_section_new_sub_cat_form_input"
                                    placeholder="Nom"
                                    onChange={(e) =>
                                      setNewSubCategory({
                                        ...newSubCategory,
                                        name: e.target.value,
                                      })
                                    }
                                  />

                                  <input
                                    type="text"
                                    placeholder="Nom Japonnais"
                                    className="Dashboard_content_section_new_sub_cat_form_input"
                                    onChange={(e) =>
                                      setNewSubCategory({
                                        ...newSubCategory,
                                        japanese: e.target.value,
                                      })
                                    }
                                  />
                                  <textarea
                                    placeholder="Description"
                                    className="Dashboard_content_section_new_sub_cat_form_textarea"
                                    onChange={(e) =>
                                      setNewSubCategory({
                                        ...newSubCategory,
                                        description: e.target.value,
                                      })
                                    }
                                  />

                                  <div
                                    className="Dashboard_content_section_new_sub_cat_form_button"
                                    onClick={create_new_sub_category}
                                  >
                                    Créer
                                  </div>
                                  <div
                                    className="Dashboard_content_section_new_sub_cat_form_back"
                                    onClick={() =>
                                      setNewSubCategory({
                                        status: false,
                                        category: 0,
                                      })
                                    }
                                  >
                                    Annuler
                                  </div>
                                </div>
                              )}
                          </div>
                          {articles.map((article) => {
                            if (category.id === article.category) {
                              return (
                                <div className="Dashboard_content_section_article">
                                  <div
                                    className="Dashboard_content_section_article_title"
                                    onClick={() => openArticle(article.id)}
                                  >
                                    <span>
                                      {article.name} - {article.price} €
                                    </span>
                                    {article.opened !== 1 ? (
                                      <ArrowRightSquare
                                        size={24}
                                        color="#FFF"
                                      />
                                    ) : (
                                      <ArrowDownSquare size={24} color="#FFF" />
                                    )}
                                  </div>
                                  {article.opened === 1 && (
                                    <>
                                      <input
                                        type="text"
                                        className="Dashboard_content_section_article_name"
                                        value={article.name}
                                        onChange={(e) =>
                                          update_article(e, article.id, "name")
                                        }
                                        onBlur={() => setNeedSave(true)}
                                      />
                                      <input
                                        type="text"
                                        className="Dashboard_content_section_article_ref"
                                        placeholder="Référence"
                                        value={article.ref}
                                        onChange={(e) =>
                                          update_article(e, article.id, "ref")
                                        }
                                        onBlur={() => setNeedSave(true)}
                                      />
                                      <textarea
                                        className="Dashboard_content_section_article_description"
                                        value={article.description}
                                        onChange={(e) =>
                                          update_article(
                                            e,
                                            article.id,
                                            "description"
                                          )
                                        }
                                        onBlur={() => setNeedSave(true)}
                                        placeholder="Description"
                                      />
                                      <div
                                        className="Dashboard_content_section_article_image"
                                        style={
                                          article.image && {
                                            backgroundImage: `url(https://sushinari.fr/uploads/${article.image})`,
                                          }
                                        }
                                      >
                                        <div className="Dashboard_content_section_article_image_input_mask">
                                          <Camera size={24} color="#000" />
                                        </div>
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={(e) =>
                                            upload_image(e, article.id)
                                          }
                                          className="Dashboard_content_section_article_image_input"
                                        />
                                      </div>
                                      <select
                                        className="Dashboard_content_section_article_category"
                                        value={article.category}
                                        onChange={(e) =>
                                          update_article(
                                            e,
                                            article.id,
                                            "category"
                                          )
                                        }
                                        onBlur={() => setNeedSave(true)}
                                      >
                                        {categories.map((category) => (
                                          <option value={category.id}>
                                            {category.name}
                                          </option>
                                        ))}
                                      </select>
                                      <select
                                        className="Dashboard_content_section_article_sub_category"
                                        value={article.sub_category}
                                        onChange={(e) =>
                                          update_article(
                                            e,
                                            article.id,
                                            "sub_category"
                                          )
                                        }
                                        onBlur={() => setNeedSave(true)}
                                      >
                                        {categories
                                          .find(
                                            (category) =>
                                              category.id === article.category
                                          )
                                          .sub_category.map((sub_category) => (
                                            <option value={sub_category.id}>
                                              {sub_category.name}
                                            </option>
                                          ))}
                                      </select>
                                      <input
                                        type="number"
                                        step={0.01}
                                        min={0}
                                        onChange={(e) =>
                                          update_article(e, article.id, "price")
                                        }
                                        onBlur={() => setNeedSave(true)}
                                        value={article.price}
                                        className="Dashboard_content_section_article_price"
                                      />
                                      <div className="Dashboard_content_section_article_status_buttons">
                                        <div
                                          className={
                                            !article.status
                                              ? "Dashboard_content_section_article_status_button"
                                              : "Dashboard_content_section_article_status_button_actif"
                                          }
                                          onClick={(e) =>
                                            toggle_button_article(
                                              e,
                                              article.id,
                                              "status"
                                            )
                                          }
                                        >
                                          ACTIF
                                        </div>
                                        <div
                                          className={
                                            !article.menu_midi
                                              ? "Dashboard_content_section_article_status_button"
                                              : "Dashboard_content_section_article_status_button_actif"
                                          }
                                          onClick={(e) =>
                                            toggle_button_article(
                                              e,
                                              article.id,
                                              "menu_midi"
                                            )
                                          }
                                        >
                                          Menu midi
                                        </div>
                                        <div
                                          className={
                                            !article.menu_soir
                                              ? "Dashboard_content_section_article_status_button"
                                              : "Dashboard_content_section_article_status_button_actif"
                                          }
                                          onClick={(e) =>
                                            toggle_button_article(
                                              e,
                                              article.id,
                                              "menu_soir"
                                            )
                                          }
                                        >
                                          Menu soir
                                        </div>
                                        <div
                                          className={
                                            !article.dispo_magasins
                                              ? "Dashboard_content_section_article_status_button"
                                              : "Dashboard_content_section_article_status_button_actif"
                                          }
                                          onClick={(e) =>
                                            toggle_button_article(
                                              e,
                                              article.id,
                                              "dispo_magasins"
                                            )
                                          }
                                        >
                                          Dispo Magasins
                                        </div>
                                        <div
                                          className={
                                            !article.cc_menu
                                              ? "Dashboard_content_section_article_status_button"
                                              : "Dashboard_content_section_article_status_button_actif"
                                          }
                                          onClick={(e) =>
                                            toggle_button_article(
                                              e,
                                              article.id,
                                              "cc_menu"
                                            )
                                          }
                                        >
                                          C&C Menu
                                        </div>
                                      </div>
                                      <div className="Dashboard_content_section_article_danger_zone">
                                        <div
                                          className="Dashboard_content_section_article_danger_zone_button"
                                          onClick={() =>
                                            erase_article(article.id)
                                          }
                                        >
                                          Supprimer
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        {activePage === 0 && openedOrder !== null && (
          <div className="Dashboard_content_page">
            <div className="Dashboard_content_articles_list">
              <div className="Dashboard_content_title">
                Commande n° {openedOrder}
              </div>
              {commandes.map((commande) => {
                if (commande.id === openedOrder && commande.articles) {
                  return commande.articles.map((article) => (
                    <>
                      <div
                        className="Dashboard_content_article"
                        key={article.id}
                      >
                        <div className="Dashboard_content_article_name">
                          <input
                            type="text"
                            value={article.name}
                            onChange={(e) =>
                              searchArticleInOrder(e, article.id, "name")
                            }
                            className="Dashboard_content_article_name_input"
                          />
                        </div>
                        <div className="Dashboard_content_article_quantity">
                          <input
                            type="number"
                            value={article.quantity}
                            onChange={(e) =>
                              updateArticleInOrder(e, article.id, "quantity")
                            }
                            className="Dashboard_content_article_quantity_input"
                          />
                        </div>
                        <div className="Dashboard_content_article_check">
                          <input
                            type="checkbox"
                            checked={article.check}
                            onChange={(e) =>
                              updateArticleInOrder(e, article.id, "check")
                            }
                            className="Dashboard_content_article_check_input"
                          />
                        </div>
                      </div>
                      {search.id === article.id &&
                        search.results.length > 0 && (
                          <div className="Dashboard_content_article_search_results">
                            {search.results.map((result) => (
                              <div
                                className="Dashboard_content_article_search_result"
                                onClick={() =>
                                  chooseArticleInOrder(result, article.id)
                                }
                              >
                                {result.name}
                              </div>
                            ))}
                          </div>
                        )}
                    </>
                  ));
                }
                return null;
              })}
              <div
                className="Dashboard_content_article_more_button"
                onClick={addArticleToOrder}
              >
                Ajouter un article
              </div>
            </div>
            <div className="Dashboard_content_client_info">
              {commandes.find((commande) => commande.id === openedOrder).client
                .id !== null ? (
                <>
                  <div className="Dashboard_content_client_info_title">
                    Informations client
                  </div>
                  <div className="Dashboard_content_client_info_line">
                    <div className="Dashboard_content_client_info_line_title">
                      Nom
                    </div>
                    <div className="Dashboard_content_client_info_line_content">
                      {
                        commandes.find(
                          (commande) => commande.id === openedOrder
                        ).client.name
                      }
                    </div>
                  </div>
                  <div className="Dashboard_content_client_info_line">
                    <div className="Dashboard_content_client_info_line_title">
                      Prénom
                    </div>
                    <div className="Dashboard_content_client_info_line_content">
                      {
                        commandes.find(
                          (commande) => commande.id === openedOrder
                        ).client.surname
                      }
                    </div>
                  </div>
                  <div className="Dashboard_content_client_info_line">
                    <div className="Dashboard_content_client_info_line_title">
                      Email
                    </div>
                    <div className="Dashboard_content_client_info_line_content">
                      {
                        commandes.find(
                          (commande) => commande.id === openedOrder
                        ).client.email
                      }
                    </div>
                  </div>
                  <div className="Dashboard_content_client_info_line">
                    <div className="Dashboard_content_client_info_line_title">
                      Téléphone
                    </div>
                    <div className="Dashboard_content_client_info_line_content">
                      {
                        commandes.find(
                          (commande) => commande.id === openedOrder
                        ).client.phone
                      }
                    </div>
                  </div>
                  <div className="Dashboard_content_client_info_line">
                    <div className="Dashboard_content_client_info_line_title">
                      Adresse
                    </div>
                    <div className="Dashboard_content_client_info_line_content">
                      {
                        commandes.find(
                          (commande) => commande.id === openedOrder
                        ).client.address
                      }
                    </div>
                  </div>
                  <div className="Dashboard_content_client_info_line">
                    <div className="Dashboard_content_client_info_line_title">
                      Code postal
                    </div>
                    <div className="Dashboard_content_client_info_line_content">
                      {
                        commandes.find(
                          (commande) => commande.id === openedOrder
                        ).client.postal_code
                      }
                    </div>
                  </div>
                  <div className="Dashboard_content_client_info_line">
                    <div className="Dashboard_content_client_info_line_title">
                      Ville
                    </div>
                    <div className="Dashboard_content_client_info_line_content">
                      {
                        commandes.find(
                          (commande) => commande.id === openedOrder
                        ).client.city
                      }
                    </div>
                  </div>
                  <div
                    className="Dashboard_content_client_disociate_button"
                    onClick={dissociateClientFromOrder}
                  >
                    Dissocier le client
                  </div>
                </>
              ) : (
                <>
                  <div className="Dashboard_content_client_info_title">
                    Associer un client
                  </div>
                  <div className="Dashboard_content_client_search">
                    <input type="text" placeholder="Rechercher un client" />
                  </div>
                  <div className="Dashboard_content_client_search_results">
                    {searchClient.results.map((result) => (
                      <div className="Dashboard_content_client_search_result">
                        {result.name} {result.surname}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {(user.type === 0 || user.type === "0") &&
          activePage === 1 &&
          openedCustomer !== null && (
            <div className="Dashboard_content_page">
              <div className="Dashboard_content_articles_list">
                <div className="Dashboard_content_title">
                  Client n° {openedCustomer} <br />
                  COMMANDES
                </div>
                {
                  // On recherche les commandes avec comme client l'id du client ouvert
                  commandes.map((commande) => {
                    if (commande.client === openedCustomer) {
                      return (
                        <div
                          className="Dashboard_content_article"
                          onClick={() => setOpenedOrder(commande.id)}
                        >
                          <div className="Dashboard_content_article_number">
                            {commande.id}
                          </div>
                          <div className="Dashboard_content_article_date">
                            {commande.date}
                          </div>
                          <div className="Dashboard_content_article_price">
                            {commande.price} €
                          </div>
                          <div className="Dashboard_content_article_status">
                            {commande.status === 0 ? "En cours" : "Terminée"}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                }
              </div>
              <div className="Dashboard_content_client_info">
                <div className="Dashboard_content_client_info_title">
                  Informations client
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Nom
                  </div>
                  <div className="Dashboard_content_client_info_line_content">
                    <input
                      type="text"
                      value={
                        clients.find(
                          (client) => client.id === openedCustomer
                        ) &&
                        clients.find((client) => client.id === openedCustomer)
                          .name
                      }
                      onChange={(e) => updateClient(e, openedCustomer, "name")}
                      onBlur={() => setCliNeedSave(true) && save_clients()}
                    />
                  </div>
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Prénom
                  </div>
                  <div className="Dashboard_content_client_info_line_content">
                    <input
                      type="text"
                      value={
                        clients.find(
                          (client) => client.id === openedCustomer
                        ) &&
                        clients.find((client) => client.id === openedCustomer)
                          .surname
                      }
                      onChange={(e) =>
                        updateClient(e, openedCustomer, "surname")
                      }
                      onBlur={() => setCliNeedSave(true) && save_clients()}
                    />
                  </div>
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Email
                  </div>
                  <div className="Dashboard_content_client_info_line_content">
                    <input
                      type="text"
                      value={
                        clients.find(
                          (client) => client.id === openedCustomer
                        ) &&
                        clients.find((client) => client.id === openedCustomer)
                          .email
                      }
                      onChange={(e) => updateClient(e, openedCustomer, "email")}
                      onBlur={() => setCliNeedSave(true) && save_clients()}
                    />
                  </div>
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Téléphone
                  </div>
                  <div className="Dashboard_content_client_info_line_content">
                    <input
                      type="text"
                      value={
                        clients.find(
                          (client) => client.id === openedCustomer
                        ) &&
                        clients.find((client) => client.id === openedCustomer)
                          .phone
                      }
                      onChange={(e) => updateClient(e, openedCustomer, "phone")}
                      onBlur={() => setCliNeedSave(true) && save_clients()}
                    />
                  </div>
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Adresse
                  </div>
                  <div className="Dashboard_content_client_info_line_content">
                    <input
                      type="text"
                      value={
                        clients.find(
                          (client) => client.id === openedCustomer
                        ) &&
                        clients.find((client) => client.id === openedCustomer)
                          .address
                      }
                      onChange={(e) =>
                        updateClient(e, openedCustomer, "address")
                      }
                      onBlur={() => setCliNeedSave(true) && save_clients()}
                    />
                  </div>
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Code postal
                  </div>
                  <div className="Dashboard_content_client_info_line_content">
                    <input
                      type="text"
                      value={
                        clients.find(({ id }) => id === openedCustomer) &&
                        clients.find(({ id }) => id === openedCustomer)
                          .postal_code
                      }
                      onChange={(e) =>
                        updateClient(e, openedCustomer, "postal_code")
                      }
                      onBlur={() => setCliNeedSave(true) && save_clients()}
                    />
                  </div>
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Ville
                  </div>
                  <div className="Dashboard_content_client_info_line_content">
                    <input
                      type="text"
                      value={
                        clients.find(
                          (client) => client.id === openedCustomer
                        ) &&
                        clients.find((client) => client.id === openedCustomer)
                          .city
                      }
                      onChange={(e) => updateClient(e, openedCustomer, "city")}
                      onBlur={() => setCliNeedSave(true) && save_clients()}
                    />
                  </div>
                </div>
                <div className="Dashboard_content_client_info_line">
                  <div className="Dashboard_content_client_info_line_title">
                    Magasin préféré
                  </div>
                  <div className="Dashboard_content_client_info_line_content"></div>
                </div>
              </div>
            </div>
          )}
      </div>
      {openedOrder === null && openedCustomer === null ? (
        <div className="Dashboard_menu">
          <div
            className={
              activePage === 0
                ? "Dashboard_menu_section_active"
                : "Dashboard_menu_section"
            }
            onClick={() => setActivePage(0)}
          >
            <Clipboard size={24} />
          </div>
          <div
            className={
              activePage === 1
                ? "Dashboard_menu_section_active"
                : "Dashboard_menu_section"
            }
            onClick={() => setActivePage(1)}
          >
            <Person size={24} />
          </div>
          {(user.type === 0 || user.type === "0") && (
            <div
              className={
                activePage === 2
                  ? "Dashboard_menu_section_active"
                  : "Dashboard_menu_section"
              }
              onClick={() => setActivePage(2)}
            >
              <Shop size={24} />
            </div>
          )}
          {(user.type === 0 || user.type === "0") && (
            <div
              className={
                activePage === 3
                  ? "Dashboard_menu_section_active"
                  : "Dashboard_menu_section"
              }
              onClick={() => setActivePage(3)}
            >
              <BoxSeam size={24} />
            </div>
          )}
        </div>
      ) : (
        <div
          className="Dashboard_menu_back_button"
          onClick={() => setOpenedOrder(null) || setOpenedCustomer(null)}
        >
          <ArrowLeftSquare className="ArrowLeftSquare" size={24} color="#FFF" />{" "}
          Retour
        </div>
      )}
    </div>
  );
};
export default Dashboard;
